import Vue from 'vue';
import Vuex from 'vuex';
import authService from '../auth/authService';
import { HTTPFront } from '../services/share';
import utils from '@/utils/utils';

Vue.use(Vuex);

const emptyItemData = {};

const defaultFullScreenError = {
    handler: () => false,
    active: false,
    showMessage: true,
    title: '',
    text: '',
    btnText: '',
};

const state = {
    isLoading: true,
    isInitStatus: false,
    fullScreenError: { ...defaultFullScreenError },
    authService: null,
    menuItems: [],
    usersData: null,
    types: null,
    configs: null,
};

const getters = {
    getLocale: () => utils.getLocale(),
    permissionDenied: state => state.isInitStatus && state.currentUser && state.currentUser.role.length === 0,
    currentUser: state => state.authService && state.authService.user,
    isAuthenticated: state => state.authService && state.authService.isAuthenticated,
    loadingAuth: state => !state.authService || state.authService.loading,
    validUser: state => state.authService && state.authService.validUser,
    forceLogin: state => state.authService?.forceLogin ?? true,
    token: () => authService.token,
    types: state => state.types,
    configs: state => state.configs,
};

const mutations = {
    initStatus (state) {
        state.authService = authService;
        state.isInitStatus = true;
        state.isLoading = false;
    },
    setLoading (state, value) {
        state.isLoading = value;
    },
    clearUser (state) {
        state.isInitStatus = false;
        state.currentUser = null;
    },
    // eslint-disable-next-line no-unused-vars
    logout (state) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('refreshToken');
        window.location.reload();
    },
    clearFullScreenError (state) {
        state.fullScreenError = { ...defaultFullScreenError };
    },
    setFullScreenError (state, { handler, title, text, btnText, component }) {
        const actualHandler = () => {
            state.fullScreenError.active = false;
            handler();
        };
        state.fullScreenError = { ...defaultFullScreenError, handler: actualHandler, title, text, btnText, component, active: true };
    },
    showFullScreenError (state) {
        state.fullScreenError.active = true;
    },
    setMenuItems (state, menuItems) {
        state.menuItems = menuItems.map(e => ({ ...e, ...(e.subMenu && { subMenu: { ...e.subMenu, active: false } }) }));
    },
    setUsersData (state, usersData) {
        state.usersData = usersData;
    },
    clearData (state, name) {
        state[name] = null;
    },
    clearItemData (state, name) {
        state[name] = { ...emptyItemData };
    },
    setTypes (state, types) {
        state.types = types;
    },
    setConfigs (state, configs) {
        state.configs = configs;
    }

};

const actions = {
    async initStatus ({ commit, dispatch }) {
        authService.$watch('loading', async loading => {
            if (!loading) {
                if (authService.validUser) {
                    await dispatch('loadMenu');
                }
                commit('initStatus');
            }
        });
    },
    async loadMenu ({ commit }) {
        try {
            const menuItems = (await HTTPFront.get('/menus')).data;
            commit('setMenuItems', menuItems);
        } catch (err) {
            console.log('loadMenu error', err);
        }
    },
    async logout ({ commit }) {
        commit('logout');
    },
    async handleCannotGetUserError ({ commit, dispatch }) {
        await dispatch('setLoading', true);
        commit('setFullScreenError', {
            handler: () => window.location.reload(),
            title: 'La sesión del usuario ha caducado',
            text: 'Se ha cerrado la sesión.',
            btnText: 'Iniciar sesión',
        });
    },
    login (context, { credentials }) {
        return authService.login(credentials);
    },
    manageExpiredRefreshToken ({ commit }) {
        commit('logout');
    },
    async forgotPassword (context, { email }) {
        return await HTTPFront.post('/auth/forgot-password', { email });
    },
    async resetPassword (context, { token, password }) {
        return await HTTPFront.post('/auth/reset-password', { token, password });
    },
    async checkAvailability (context, { email }) {
        return await HTTPFront.get('/auth/check-availability', { params: { email } });
    },
    async signup (context, { firstName, lastName, phone, email, password, company }) {
        return await HTTPFront.post('/auth/signup', { firstName, lastName, phone, email, password, company });
    },
    async verifyEmail (context, { token, otp }) {
        return await HTTPFront.post('/auth/verify-signup', { token, otp });
    },
    async resendEmailVerification (context, { email }) {
        return await HTTPFront.post('/auth/resend-verification', { email });
    },
    // TYPES
    async types ({ commit, getters }) {
        try {
            if (getters && getters.types) {
                return getters.types;
            }
            const rawData = await HTTPFront.get('/utils/types-by-code', { headers: { Authorization: `Bearer ${getters.token}` } });
            const types = rawData.data.types;
            commit('setTypes', types);
        } catch (err) {
            console.log('en el store - types', err);
        }
    },

    // CODES
    // TODO: VER SI SE USA EN OTRO LADO, SINO SE QUITA
    async configs ({ commit, getters }) {
        try {
            if (getters && getters.configs) {
                return getters.configs;
            }
            const rawData = await HTTPFront.get('/utils/configs-by-code', { headers: { Authorization: `Bearer ${getters.token}` } });
            const configs = rawData.data.configs;
            commit('setConfigs', configs);
            return configs;
        } catch (err) {
            console.log('en el store - configs', err);
        }
    },
    // async typesById ({ getters }, { typeId }) {
    //     try {
    //         return (await HTTPFront.get(`/types/${typeId}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
    //     } catch (err) {
    //         console.log('en el store - typesById', err);
    //     }
    // },
    async typesById ({ getters }, { configId, typeId }) {
        try {
            return (await HTTPFront.get(`/configs/${configId}/types/${typeId}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - typesById', err);
        }
    },

    // HOME - stats
    async analyticsTotals ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/totals?${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data;
        } catch (err) {
            console.log('en el store - analyticsTotals', err);
        }
    },
    async analyticsQrTotalsByType ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/qr?totals_by_type=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            const types = getters.types;
            const totalsByTypes = rawData.data.totalsByType.map(e => {
                const type = types[`${filters.config}/${e.type}`];
                e.typeName = !type ? 'Unknown' : type.name;
                return e;
            });
            return totalsByTypes;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsQrPercentageByType ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/qr?percentage_by_type=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            const types = getters.types;
            const percentageByType = rawData.data.percentageByType.map(e => {
                const type = types[`${filters.config}/${e.type}`];
                e.typeName = !type ? 'Unknown' : type.name;
                return e;
            });
            return percentageByType;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsTotalByCountry ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?totals_by_country=true&totals_by_country_max_values=100&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.totalsByCountries;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsTotalByProvince ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?totals_by_province=true&totals_by_province_max_values=100&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.totalsByProvinces;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsTotalByHours ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?totals_by_hours=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.totalsByHours;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsTotalByWeekday ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?totals_by_weekday=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.totalsByWeekdays;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsPercentageByHours ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?percentage_by_hour=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.percentageByHours;
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },
    async analyticsVisualizationsPercentageByWeekday ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const configQuery = utils.getQueryConfig(filters);
            const rawData = await HTTPFront.get(`/analytics/visualizations?percentage_by_weekday=true&${dateQuery}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return rawData.data.percentageByWeekdays.filter(e => e.day !== 'Others');
        } catch (err) {
            console.log('en el store - analyticsQr', err);
        }
    },

    // REPORTING - general table
    async analyticsConsolidationReportingTable ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const pageFilters = utils.getQueryPage(filters, dateQuery);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            const rawData = await HTTPFront.get(`/analytics/consolidation?${dateQuery}&${pageFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            const configs = getters.configs;
            const consolidationByType = rawData.data.data.map(e => {
                const config = configs[e.config];
                e.configName = !config ? 'Desconocido' : config.name;
                const type = config?.types[e.type];
                e.typeName = !type ? 'Desconocido' : type.name;
                e.typeId = !type ? 'Desconocido' : type.id;
                return e;
            });
            const response = {
                data: consolidationByType,
                total: rawData.data.total,
            };
            return response;
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    async analyticsConsolidationPdf ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const productFilters = utils.getQueryProducts(filters?.products);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            const { data } = await HTTPFront.get(`/analytics/consolidation/pdf?${dateQuery}${productFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.pdf`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    async analyticsConsolidationSpreadsheet ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const productFilters = utils.getQueryProducts(filters?.products);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            const { data } = await HTTPFront.get(`/analytics/consolidation/spreadsheet?${dateQuery}${productFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.xls`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    // REGISTRY
    async tracks ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const pageFilters = utils.getQueryPage(filters, dateQuery);
            const typeFilters = utils.getQueryTypes(filters?.types);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            return (await HTTPFront.get(`/tracks?${dateQuery}${pageFilters}${typeFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - tracks', err);
        }
    },

    async tracksSpreadsheet ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const pageFilters = `&page=1&size=${filters?.trackIds.length}`;
            const trackIdFilters = utils.getQueryTrackIds(filters?.trackIds);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            const { data } = await HTTPFront.get(`/reports/tracks/spreadsheet?${dateQuery}${pageFilters}${trackIdFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.xls`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - tracksSpreadsheet', err);
        }
    },
    async tracksPdf ({ getters }, filters) {
        try {
            const dateQuery = utils.getQueryDate(filters);
            const pageFilters = `&page=1&size=${filters?.trackIds.length}`;
            const trackIdFilters = utils.getQueryTrackIds(filters?.trackIds);
            const configQuery = filters.config ? `&config=${filters.config}` : '';
            const { data } = await HTTPFront.get(`/reports/tracks/pdf?${dateQuery}${pageFilters}${trackIdFilters}${configQuery}`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.pdf`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - tracksPdf', err);
        }
    },

    async getQrPNG ({ getters }, trackId) {
        try {
            const { data } = await HTTPFront.get(`/tracks/${trackId}/qr/png`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.png`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    async getQrZPL ({ getters }, trackId) {
        try {
            const { data } = await HTTPFront.get(`/tracks/${trackId}/qr/zpl`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `${utils.getTodayString('-')}.zpl`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    async getQrPNGByQuery ({ getters }, trackId) {
        try {
            const { data } = await HTTPFront.get(`/tracks/${trackId}/qr/png-by-query?token=${getters.token}`, { responseType: 'blob' });
            const newBlob = new Blob([data], { type: 'image/png' });
            const url = URL.createObjectURL(newBlob);
            return url;
        } catch (err) {
            console.log('en el store - analyticsReportingTable', err);
        }
    },

    async getQrLink ({ getters }, trackId) {
        try {
            return (await HTTPFront.get(`/tracks/${trackId}/qr/raw`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getQrLink', err);
        }
    },

    // QR emitido
    async issuedQr (context, { hash, language, notCount }) {
        try {
            return await HTTPFront.get(`/view/${hash}?language=${language}&not_count=${notCount ?? false}`);
        } catch (err) {
            console.log('en el store - issuedQr', err);
        }
    },

    // Assets
    async getAsset ({ getters }, { assetKey, assetSubKey, token }) {
        token = token ?? `Bearer ${getters.token}`;
        try {
            if (assetSubKey != null) {
                return await HTTPFront.get(`/assets/${assetKey}/${assetSubKey}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                });
            } else {
                return await HTTPFront.get(`/assets/${assetKey}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                });
            }
        } catch (err) {
            console.log('en el store - getAsset', err);
        }
    },

    async saveSectionHeader ({ getters }, { configId, sectionId, headerType, assetType, value }) {
        const body = {
            headerType: headerType,
            assetType: assetType,
            value: value,
        };
        try {
            return await HTTPFront.post(`/configs/${configId}/sections/${sectionId}/header`, body, { headers: { Authorization: `${getters.token}` } });
        } catch (err) {
            console.log('en el store - saveSectionHeader', err);
        }
    },

    // Notification
    async getBrandData ({ getters }, { brandId }) {
        try {
            return (await HTTPFront.get(`/config/config-by-brand/${brandId}`, { headers: { Authorization: `Bearer ${getters.token}` } }));
        } catch (err) {
            console.log('en el store - getBrandData', err);
        }
    },

    async sendIdentifiers ({ getters }, { since, until, identifiers, types, configs }) {
        try {
            return (await HTTPFront.post('/tracks/search', { since, until, identifiers, types, configs }, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - sendIdentifiers', err);
        }
    },

    async getTracks ({ getters }, { token }) {
        try {
            // TODO: IMPLEMENTAR LA PAGINACION
            return (await HTTPFront.get(`/tracks/search/${token}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getTracks', err);
        }
    },

    async sendTagManual ({ getters }, body) {
        try {
            const response = await HTTPFront.post('/tracks', body, { headers: { Authorization: `Bearer ${getters.token}` } });
            return response.data;
        } catch (error) {
            console.error('Error en sendTagManual', error.response.data);
        }
    },

    async createNotice ({ getters }, { token, type, value, startAt, endAt }) {
        try {
            return (await HTTPFront.post(`/alerts/${token}`, { type, value, startAt, endAt }, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - createNotice', err);
        }
    },

    async getRecords ({ getters }, { page, size, configs }) {
        try {
            // TODO: IMPLEMENTAR LA PAGINACION
            return (await HTTPFront.get('/record', { params: { page: page, size: size, configs: configs } }, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getRecord', err);
        }
    },

    async getUserById ({ getters }, userId) {
        try {
            let data = (await HTTPFront.get(`/users/${userId}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
            return { id: userId, ...data };
        } catch (err) {
            console.log('en el store - getUserById', err);
        }
    },

    // API KEY
    async getUsersMapByIds ({ dispatch }, userIds) {
        const uniqueUserIds = [...new Set(userIds)];
        const results = await Promise.allSettled(uniqueUserIds.map(id => dispatch('getUserById', id)));
        return Object.fromEntries(results.filter(r => r.status === 'fulfilled').map(({ value }) => [value.id, value]));
    },

    async getApikey ({ getters }) {
        try {
            return (await HTTPFront.get('/apikey', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getApikey', err);
        }
    },

    async createApiKey ({ getters }, payload) {
        const { scope, publicKeyPem, json, signature } = payload;
        try {
            const response = await HTTPFront.post('/apikey', { scope, publicKeyPem, json, signature }, { headers: { Authorization: `Bearer ${getters.token}` } });
            return response.data;
        } catch (error) {
            console.error('Error en createApiKey', error);
        }
    },

    async deleteApiKey ({ getters }, scope) {
        try {
            await HTTPFront.delete(`/apikey/${scope}`, { headers: { Authorization: `Bearer ${getters.token}` } });
            return true;
        } catch (error) {
            console.error('Error en deleteApiKey', error);
        }
    },

    // User configuration

    async getUserConfiguration ({ getters }) {
        try {
            return (await HTTPFront.get('/current-user/data', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getUserConfiguration', err);
        }
    },

    async getUserConfigurationCompany ({ getters }) {
        try {
            return (await HTTPFront.get('/current-user/company', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getUserConfigurationCompany', err);
        }
    },

    async updateUserConfiguration ({ getters }, payload) {
        const { firstName, lastName, phone } = payload;
        try {
            const response = await HTTPFront.patch('/current-user/data', { firstName, lastName, phone }, { headers: { Authorization: `Bearer ${getters.token}` } });
            return response.data;
        } catch (error) {
            console.error('Error en updateUserConfiguration', error);
        }
    },

    async updateUserPassword ({ getters }, payload) {
        const { currentPassword, newPassword } = payload;
        const response = await HTTPFront.post('/current-user/change-password', { currentPassword, newPassword }, { headers: { Authorization: `Bearer ${getters.token}` } });
        return response.data;
    },

    async generateSecret ({ getters }, payload) {
        const { method } = payload;
        try {
            const response = await HTTPFront.post('/current-user/2fa/challenge', { method }, { headers: { Authorization: `Bearer ${getters.token}` } });
            return response.data;
        } catch (error) {
            console.error('Error en generateSecret', error);
        }
    },

    async generateQR ({ getters }, secret) {
        try {
            const { data } = await HTTPFront.get(`/current-user/2fa/qr/${secret}`, {
                headers: { Authorization: `Bearer ${getters.token}` },
                responseType: 'blob',
            });
            const newBlob = new Blob([data], { type: 'image/png' });
            return newBlob;
        } catch (error) {
            console.error('Error en generateQR', error);
        }
    },

    async generateCodes ({ getters }, payload) {
        const { method, otp, secret } = payload;
        try {
            const response = await HTTPFront.post('/current-user/2fa', { method, otp, secret }, { headers: { Authorization: `Bearer ${getters.token}` } });
            return response.data;
        } catch (error) {
            console.error('Error en generateCodes', error);
        }
    },

    async delete2fa ({ getters }, payload) {
        try {
            const { method, otp } = payload;
            await HTTPFront.delete('/current-user/2fa', { headers: { Authorization: `Bearer ${getters.token}` }, data: { method, otp } });
            return true;
        } catch (error) {
            console.error('Error en delete2fa', error);
        }
    },

    async get2faStatus ({ getters }) {
        try {
            return (await HTTPFront.get('/current-user/2fa', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - get2faStatus', err);
        }
    },

    async send2faLogin ({ getters }, payload) {
        const { token, otp } = payload;
        try {
            const response = await HTTPFront.post('/auth/login/2fa', { token, otp });
            return response.data;
        } catch (error) {
            console.error('Error en send2faLogin', error);
        }
    },

    async recoverAccount ({ getters }, payload) {
        const { email } = payload;
        try {
            const response = await HTTPFront.post('/auth/recover-account', { email });
            return response;
        } catch (error) {
            console.error('Error en recoverAccount', error);
        }
    },
    // Utils
    async getIcons ({ getters }) {
        try {
            return (await HTTPFront.get('/utils/icons', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getIcons', err);
        }
    },
    async getAttributeTypes ({ getters }) {
        try {
            return (await HTTPFront.get('/utils/attribute-types', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getAttributeTypes', err);
        }
    },
    // Config service (tag configuration)
    async getSectionsByConfig ({ getters }, payload) {
        const { configId } = payload;
        try {
            return (await HTTPFront.get(`/configs/${configId}/sections`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getSectionsByConfig', err);
        }
    },
    async getConfigs ({ getters }, payload) {
        const { configId } = payload;
        try {
            return (await HTTPFront.get(`/configs/${configId}`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getConfigs', err);
        }
    },
    async updateConfigs ({ getters }, payload) {
        const { configId, configs } = payload;
        try {
            return (await HTTPFront.put(`/configs/${configId}`, configs, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - updateConfigs', err);
        }
    },
    async getMockView ({ getters }, payload) {
        const { configId } = payload;
        try {
            return (await HTTPFront.get(`/configs/${configId}/mock-view`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getMockView', err);
        }
    },

    async getJsonMock ({ getters }, payload) {
        const { configId } = payload;
        try {
            return (await HTTPFront.get(`/configs/${configId}/mock-payload`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getJsonMock', err);
        }
    },

    async getConfigTypes ({ getters }, payload) {
        const { configId } = payload;
        try {
            return (await HTTPFront.get(`/configs/${configId}/types`, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getConfigTypes', err);
        }
    },

    async newConfigs ({ getters }, payload) {
        const { configs } = payload;
        try {
            return (await HTTPFront.post('/configs', configs, { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - newConfigs', err);
        }
    },

    async getAllConfigs ({ getters }, payload) {
        try {
            return (await HTTPFront.get('/configs', { headers: { Authorization: `Bearer ${getters.token}` } })).data;
        } catch (err) {
            console.log('en el store - getAllConfigs', err);
        }
    },

    async deleteLogo ({ getters }, { configId, logoId }) {
        try {
            return await HTTPFront.delete(`/configs/${configId}/logos/${logoId}`, { headers: { Authorization: `${getters.token}` } });
        } catch (err) {
            console.log('en el store - deleteLogo', err);
        }
    },

    async saveSectionLogo ({ getters }, { configId, value, index }) {
        const body = {
            index: index,
            value: value.replace(/^data:image\/[a-z]+;base64,/, '')
        };

        try {
            return await HTTPFront.post(`/configs/${configId}/logos`, body, { headers: { Authorization: `${getters.token}` } });
        } catch (err) {
            console.log('en el store - saveSectionLogo', err);
        }
    },

    async deleteSectionHeader ({ getters }, { configId, sectionId, headerId }) {
        try {
            return await HTTPFront.delete(`/configs/${configId}/sections/${sectionId}/header/${headerId}`, { headers: { Authorization: `${getters.token}` } });
        } catch (err) {
            console.log('en el store - deleteSectionHeader', err);
        }
    },
    async getMapImage ({ getters }, { startDate, endDate, topLeftPoint, fieldSize }) {
        try {
            const body = {
                startDate: startDate,
                endDate: endDate,
                topLeftPoint: topLeftPoint,
                fieldSize: fieldSize,
            };
            // Make the POST request
            const response = await HTTPFront.post('/sentinel/download-map-image', body);
            return response.data;
        } catch (err) {
            console.log('en el store - getMapImage:', err);
        }
    },
    async getMapImageWithMetaData ({ getters }, { startDate, endDate, topLeftPoint, fieldSize, evi, ndvi }) {
        try {
            const body = {
                start_date: startDate,
                end_date: endDate,
                top_left_point: topLeftPoint,
                field_size: fieldSize,
            };
            // Make the POST request
            const response = await HTTPFront.post(`/sentinel/percentage?evi=${evi}&ndvi=${ndvi}`, body);
            return response.data;
        } catch (err) {
            console.log('Error in getMapImageWithMetaData:', err);
        }
    },
    async getLocationByAddress ({ getters }, address) {
        const result = await HTTPFront.get(`/geo-search?address=${address}`);
        return result.data;
    },
    async getLocationByCoordinates ({ getters }, coordinates) {
        const result = await HTTPFront.get(`/geo-search-inverse?lat=${coordinates.lat}&lon=${coordinates.lon}`);
        return result.data;
    },
    // CERTIFICATES
    async certificatesGetData ({ getters }, token) {
        try {
            return (await HTTPFront.get(`/certificate/${token}`)).data;
        } catch (err) {
            console.log('en el store - CertificatesGetData', err);
        }
    },
    async certificateInit ({ getters }, payload) {
        try {
            const { token } = payload;
            const { body } = payload;
            return (await HTTPFront.post(`/certificate/${token}/init`, body)).data;
        } catch (err) {
            console.log('en el store - CertificateInit', err);
        }
    },
    async certificateValidateMail ({ getters }, payload) {
        try {
            const { token, pin } = payload;
            return (await HTTPFront.post(`/certificate/${token}/validate`, { pin })).data;
        } catch (err) {
            console.log('en el store - CertificateValidateMail', err);
        }
    },
    async certificateEmit ({ getters }, payload) {
        try {
            const { token, body } = payload;
            return (await HTTPFront.post(`/certificate/${token}/emit`, body)).data;
        } catch (err) {
            console.log('en el store - CertificateEmit', err);
        }
    },
    async certifcateResendMail ({ getters }, token) {
        try {
            return (await HTTPFront.post(`/certificate/${token}/resend-mail`)).data;
        } catch (err) {
            console.log('en el store - CertificateResendMail', err);
        }
    },

    async certificateDownload ({ getters }, token) {
        try {
            const { data } = await HTTPFront.get(`/certificate/${token}/download`, { headers: { Authorization: `Bearer ${getters.token}` }, responseType: 'blob' });
            const fileName = `certificado-${utils.getTodayString('-')}.pdf`;
            utils.saveFile(data, fileName);
        } catch (err) {
            console.log('en el store - CertificateDownload', err);
        }
    },
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {
    }
});
